import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  LexicalTypeaheadMenuPlugin,
  MenuOption,
  MenuTextMatch,
  useBasicTypeaheadTriggerMatch
} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_EDITOR,
  LexicalCommand,
  TextNode,
  createCommand
} from 'lexical';
import { mergeRegister } from '@lexical/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import styles from './../../index.module.scss';
import { $createVariableNode } from '../../nodes/VariableNode';
import { VariableTypes } from '../../Editor';

const PUNCTUATION =
  '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;';
const NAME = '\\b[A-Z][^\\s' + PUNCTUATION + ']';

const DocumentVariableRegex = {
  NAME,
  PUNCTUATION
};

const PUNC = DocumentVariableRegex.PUNCTUATION;

const TRIGGERS = ['{'].join('');

// Chars we expect to see in a variable (non-space, non-punctuation).
const VALID_CHARS = '[^' + TRIGGERS + PUNC + '\\s]';

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
  '(?:' +
  '\\.[ |$]|' + // E.g. "r. " in "Mr. Smith"
  ' |' + // E.g. " " in "Josh Duck"
  '[' +
  PUNC +
  ']|' + // E.g. "-' in "Salier-Hellendag"
  ')';

const LENGTH_LIMIT = 75;

const AtSignVariableRegex = new RegExp(
  '(^|\\s|\\()(' +
    '[' +
    TRIGGERS +
    ']' +
    '((?:' +
    VALID_CHARS +
    VALID_JOINS +
    '){0,' +
    LENGTH_LIMIT +
    '})' +
    ')$'
);

// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT = 50;

// Regex used to match alias.
const AtSignVariableRegexAliasRegex = new RegExp(
  '(^|\\s|\\()(' +
    '[' +
    TRIGGERS +
    ']' +
    '((?:' +
    VALID_CHARS +
    '){0,' +
    ALIAS_LENGTH_LIMIT +
    '})' +
    ')$'
);

// At most, 5 suggestions are shown in the popup.
const SUGGESTION_LIST_LENGTH_LIMIT = 5;

export const INSERT_VARIABLE_COMMAND: LexicalCommand<unknown> =
  createCommand('INSERT_VARIABLE_COMMAND');

const variableCache = new Map();

export type VariableOption = {
  text: string;
  variable: (typeof VariableTypes)[keyof typeof VariableTypes];
  getPreviewValue?: () => string;
};

const lookupService = {
  search(
    variables: Array<VariableOption>,
    string: string,
    callback: (results: Array<VariableOption>) => void
  ): void {
    setTimeout(() => {
      const results = variables.filter((variable) =>
        variable.text.toLowerCase().includes(string.toLowerCase())
      );
      callback(results);
    }, 500);
  }
};

function useVariableLookup(
  variables: Array<VariableOption>,
  variableString: string | null
) {
  const [results, setResults] = useState<Array<VariableOption>>([]);

  useEffect(() => {
    const cachedResults = variableCache.get(variableString);

    if (variableString == null) {
      setResults([]);
      return;
    }

    if (cachedResults === null) {
      return;
    } else if (cachedResults !== undefined) {
      setResults(cachedResults);
      return;
    }

    variableCache.set(variableString, null);

    lookupService.search(variables, variableString, (newResults) => {
      variableCache.set(variableString, newResults);
      setResults(newResults);
    });
  }, [variableString]);

  return results;
}

function checkForAtSignVariable(
  text: string,
  minMatchLength: number
): MenuTextMatch | null {
  let match = AtSignVariableRegex.exec(text);

  if (match === null) {
    match = AtSignVariableRegexAliasRegex.exec(text);
  }
  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[3];
    if (matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2]
      };
    }
  }
  return null;
}

function getPossibleQueryMatch(text: string): MenuTextMatch | null {
  return checkForAtSignVariable(text, 0);
}

class VariableTypeaheadOption extends MenuOption {
  text: string;
  variable: string;
  constructor(variableOption: VariableOption) {
    super(variableOption.text);
    this.text = variableOption.text;
    this.variable = variableOption.variable;
  }
}

function VariableTypeaheadMenuItem({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option
}: {
  index: number;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  option: VariableTypeaheadOption;
}) {
  let className = 'item rounded-8';
  if (isSelected) {
    className += ' selected';
  }
  return (
    <li
      key={option.key}
      tabIndex={-1}
      className={className}
      ref={option.setRefElement}
      role="option"
      aria-selected={isSelected}
      id={'typeahead-item-' + index}
      onMouseEnter={onMouseEnter}
      onClick={onClick}>
      <span className="text-label-md text-npl-text-icon-on-light-surface-primary">
        {option.text}
      </span>
    </li>
  );
}

export default function VariablePlugin({
  variables
}: {
  variables: Array<VariableOption>;
}): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  const [queryString, setQueryString] = useState<string | null>(null);

  const results = useVariableLookup(variables, queryString);

  const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
    minLength: 0
  });

  const options = useMemo(
    () =>
      results
        .map((result) => new VariableTypeaheadOption(result))
        .slice(0, SUGGESTION_LIST_LENGTH_LIMIT),
    [results]
  );

  const onSelectOption = useCallback(
    (
      selectedOption: VariableTypeaheadOption,
      nodeToReplace: TextNode | null,
      closeMenu: () => void
    ) => {
      editor.update(() => {
        const variableNode = $createVariableNode(
          selectedOption.variable,
          selectedOption.text
        );
        if (nodeToReplace) {
          nodeToReplace.replace(variableNode);
        }
        variableNode.select();
        closeMenu();
      });
    },
    [editor]
  );

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand<unknown>(
        INSERT_VARIABLE_COMMAND,
        () => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            selection.insertText(' {');
          }

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerNodeTransform(TextNode, (_textNode: TextNode) => {
        //TODO: @Nick convert text nodes into variable nodes if they match with {}
      })
    );
  }, [editor]);

  const checkForVariableMatch = useCallback(
    (text: string) => {
      const slashMatch = checkForSlashTriggerMatch(text, editor);
      if (slashMatch !== null) {
        return null;
      }
      return getPossibleQueryMatch(text);
    },
    [checkForSlashTriggerMatch, editor]
  );

  return (
    <LexicalTypeaheadMenuPlugin<VariableTypeaheadOption>
      onQueryChange={setQueryString}
      onSelectOption={onSelectOption}
      triggerFn={checkForVariableMatch}
      options={options}
      menuRenderFn={(
        anchorElementRef,
        { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }
      ) =>
        anchorElementRef.current && results.length
          ? ReactDOM.createPortal(
              <div
                className={`${styles['typeahead-popover']} variable-menu`}>
                <ul className="!p-8">
                  {options.map((option, i: number) => (
                    <VariableTypeaheadMenuItem
                      index={i}
                      isSelected={selectedIndex === i}
                      onClick={() => {
                        setHighlightedIndex(i);
                        selectOptionAndCleanUp(option);
                      }}
                      onMouseEnter={() => {
                        setHighlightedIndex(i);
                      }}
                      key={option.key}
                      option={option}
                    />
                  ))}
                </ul>
              </div>,
              anchorElementRef.current
            )
          : null
      }
    />
  );
}
