import withComponentClassName from '@/utility/withComponentClassName';
import classNames from 'classnames';
import React from 'react';

function AnimatedShowHideDiv({ children, show, duration = 200 }) {
  return (
    <div
      className={classNames(
        `grid w-full transition-all duration-${duration} overflow-hidden ease-out`,
        {
          'grid-rows-[0fr]': !show,
          'grid-rows-[1fr]': show
        }
      )}>
      <div className="overflow-hidden">{children}</div>
    </div>
  );
}

export default withComponentClassName(AnimatedShowHideDiv);
