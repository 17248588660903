import classNames from 'classnames';
import React, { FC, useState } from 'react';
import NPLInputTopSection from './components/NPLInputTopSection';
import NPLInputLeadTailSection from './components/NPLInputLeadTailSection';
import { useMaskito } from '@maskito/react';
import type { MaskitoOptions } from '@maskito/core';

// Define the type for the selection options
interface Option {
  label: string;
  value: string | number;
  optionsDisplay?: string;
}

// Define the type for the leadSelection and tailSelection props
interface SelectionProps {
  placeholder: string;
  value: string;
  options: Option[];
  onChange: () => void;
}

// NPLInput props type
interface NPLInputProps {
  label?: string;
  labelDescription?: string;
  isRequired?: boolean;
  tooltipText?: string;
  buttonText?: string;
  rounded?: boolean;
  buttonOnClick?: () => void;

  leadText?: string;
  leadIconName?: string;
  leadSelection?: SelectionProps;

  placeholder?: string;
  disabled?: boolean;
  value?: string;
  name?: string;
  inputType?: string;

  tailSelection?: SelectionProps;
  tailText?: string;
  tailIconName?: string;
  tailOnClick?: () => void;

  supportingText?: string;
  error?: string;
  maxTextCount?: number;
  testId?: string;

  maskPattern?: MaskitoOptions;

  hasChinContent?: boolean;

  onInput?: (e: React.FormEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  onBlur?: () => void;

  isSlugInput?: boolean;
  tailSupportingText?: string;
  isMoneyValue?: boolean;
}

const NPLInput: FC<NPLInputProps> = ({
  label,
  labelDescription,
  isRequired,
  tooltipText,
  buttonText,
  buttonOnClick,
  rounded,

  leadText,
  leadIconName,
  leadSelection,

  placeholder,
  disabled,
  value = '',
  inputType = 'text',

  tailSelection,
  tailText,
  tailIconName,
  tailOnClick,

  supportingText,
  error,
  maxTextCount,
  testId,

  maskPattern,

  hasChinContent,
  onInput,
  onClick,
  onBlur,
  name,

  isSlugInput,
  tailSupportingText,
  isMoneyValue = false
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useMaskito({ options: maskPattern });

  const inputFocusClassNames = `border-1 border-npl-yellow-light-solid-12 ${
    isFocused && hasChinContent ? 'rounded-b-0' : ''
  }`;

  const hasLeadProps = leadText || leadIconName || leadSelection;
  const hasTailProps = tailText || tailIconName || tailSelection;

  const showNPLInputTopSection =
    label || labelDescription || tooltipText || buttonText;
  return (
    <div className="c-NPLInput">
      {showNPLInputTopSection && (
        <div className="mb-6">
          <NPLInputTopSection
            label={label}
            labelDescription={labelDescription}
            isRequired={isRequired}
            tooltipText={tooltipText}
            buttonText={buttonText}
            buttonOnClick={buttonOnClick}
          />
        </div>
      )}
      <div
        className={classNames([
          'c-NPLInput h-48 flex items-center border border-npl-neutral-light-solid-7 text-body-sm shadow-npl-styles-button-shadow transition-all',
          {
            'rounded-t-8': hasChinContent,
            'rounded-8': !hasChinContent,
            'bg-npl-neutral-light-solid-3': disabled,
            'overflow-hidden rounded-full': rounded
          },
          isFocused && inputFocusClassNames
        ])}>
        {hasLeadProps && (
          <NPLInputLeadTailSection
            iconOnClick=""
            text={leadText}
            iconName={leadIconName}
            selectionProps={leadSelection}
            isTail={false}
            isSlugInput={isSlugInput}
          />
        )}

        <input
          ref={inputRef}
          onInput={onInput}
          onClick={onClick}
          name={name}
          id={name}
          data-test-id={
            testId ||
            `npl-input-${label?.replace(/\s/g, '-').toLowerCase() || ''}`
          }
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            if (onBlur) {
              onBlur();
            }
            setIsFocused(false);
          }}
          type={inputType}
          disabled={disabled}
          value={value}
          placeholder={disabled ? '' : placeholder}
          className={classNames([
            `w-full h-full flex-shrink flex-grow rounded-8 px-16 py-12 text-body-md text-neutral-10 outline-none transition-all`,
            {
              'rounded-l-0': hasLeadProps,
              'rounded-r-0': hasTailProps,
              'ring-1 ring-npl-error-light-11': error,
              'text-npl-text-icon-on-light-surface-tertiary': disabled,
              'text-right': isMoneyValue
            }
          ])}
        />
        {hasTailProps && (
          <NPLInputLeadTailSection
            text={tailText}
            iconName={tailIconName}
            selectionProps={tailSelection}
            iconOnClick={tailOnClick}
            isTail
          />
        )}
      </div>
      {(tailSupportingText || supportingText || error || maxTextCount) && (
        <div className="flex items-center mt-6 text-body-sm text-npl-text-icon-on-light-surface-secondary">
          {(supportingText || error) && (
            <div
              className={`${
                error
                  ? 'text-npl-error-light-9'
                  : 'text-npl-text-icon-on-light-surface-secondary'
              }`}>
              {error ? error : supportingText}
            </div>
          )}

          {tailSupportingText && (
            <div className="flex justify-end flex-grow font-medium text-label-sm text-npl-text-tertiary-on-light">
              {tailSupportingText}
            </div>
          )}

          {maxTextCount && (
            <div className="flex justify-end flex-grow">
              {value?.length}/{maxTextCount}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NPLInput;
